<template>
  <div class="text-center">
    <template v-if="checkPermission('TarifResource.PUT.Tarif')">
      <button
        @click="editData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission('TarifResource.DELETE.Tarif.Id')">
      <button
        @click="deleteData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const TarifRepository = RepositoryFactory.get("tarif");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {

    }
  },
  props: {
    rowData: { type: Object },
    storeObject: {},
    tarifVue: { type: Object },
  },
  computed: {
    dataEdit: function () {
      return this.storeObject.state.MasterStore.dataEdit;
    }
  },
  methods: {
    editLocation() {

      this.tarifVue.dataEditLocation = { tarif : this.rowData};
      this.tarifVue.isShowModalLocation = true;
    },
    deleteData() {
      this.storeObject.dispatch("MasterStore/deleteById", { deleteRepository: TarifRepository.deleteTarif, id: this.rowData.id });
    },
    editData() {
      this.storeObject.commit("MasterStore/SET_EDIT_DATA", this.rowData);
      this.storeObject.commit("MasterStore/SHOW_MODAL");
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
  }
};
</script>
